<script setup lang="ts">
import { getSrcSetForMedia } from '@shopware-pwa/helpers-next';
import { handleClick } from '@/helpers/clickHandler';

const props = defineProps<{
  content: {
    data: {
      grid: {
        backgroundColor: string;
        iconMediaUrl: string;
        mediaUrl: string;
        name: string;
        openNewTab: boolean;
        text: string;
        media: {
          url: string | null;
          alt: string;
          title: string;
          thumbnails: {
            height: number;
            id: string | number;
            url: string | null;
            width: number;
          }[];
        };
      }[];
    };
    translated: {
      config: {
        gridSettings: {
          value: {
            autoRotate: boolean;
            headline: string;
            subheadline: string;
            navigation: boolean;
            rotationSpeed: number;
          };
        };
      };
    };
  };
}>();

type CarouselData = {
  desktopBgImage?: string;
  mobileBgImage?: string;
  srcsetMobile: string;
  srcsetDesktop: string;
  textOnBanner?: string;
  icon?: string;
  backgroundColor?: string;
  openNewTab?: boolean;
  altText?: string;
  titleText?: string;
};

const carouselSlider: CarouselData[] = [];

props.content.data.grid.forEach((item) => {
  carouselSlider.push({
    desktopBgImage: item.mediaUrl,
    mobileBgImage: item.mediaUrl,
    srcsetMobile: item.media ? getSrcSetForMedia(item.media as any) : '',
    srcsetDesktop: item.media ? getSrcSetForMedia(item.media as any) : '',
    textOnBanner: item.text,
    icon: item.iconMediaUrl,
    backgroundColor: item.backgroundColor,
    openNewTab: item.openNewTab,
    altText: item.media?.alt,
    titleText: item.media?.title,
  });
});
</script>

<template>
  <div>
    <Carousel
      :slide-data="carouselSlider"
      :title="props.content.translated.config.gridSettings.value.headline"
      :subtitle="props.content.translated.config.gridSettings.value.subheadline"
      :arrows="props.content.translated.config.gridSettings.value.navigation"
      :autoplay="props.content.translated.config.gridSettings.value.autoRotate"
      :autoplay-speed="
        props.content.translated.config.gridSettings.value.rotationSpeed * 1000
      "
      @banner-link-clicked="handleClick"
    />
  </div>
</template>
